define("fx/fragment-types", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "__schema": {
      "types": [{
        "kind": "UNION",
        "name": "CellEntity",
        "possibleTypes": [{
          "name": "Budget"
        }, {
          "name": "BudgetPeriod"
        }, {
          "name": "Currency"
        }, {
          "name": "ExpectedVariation"
        }, {
          "name": "FinancialFlow"
        }, {
          "name": "IntraPeriodUnitSoldQuantity"
        }, {
          "name": "MonthlyCurrencyValue"
        }, {
          "name": "MonthlyProductValue"
        }, {
          "name": "MonthlyValue"
        }, {
          "name": "PeriodCurrency"
        }, {
          "name": "PeriodProduct"
        }, {
          "name": "Price"
        }, {
          "name": "Product"
        }, {
          "name": "ProductionCycle"
        }, {
          "name": "ProjectedProfit"
        }, {
          "name": "Rate"
        }, {
          "name": "Ratio"
        }, {
          "name": "YearlyTotal"
        }]
      }, {
        "kind": "UNION",
        "name": "FilterEntity",
        "possibleTypes": [{
          "name": "BudgetPeriod"
        }, {
          "name": "Currency"
        }, {
          "name": "Product"
        }]
      }, {
        "kind": "INTERFACE",
        "name": "Periodic",
        "possibleTypes": [{
          "name": "Analysis"
        }, {
          "name": "FinancialFlow"
        }, {
          "name": "IntraPeriodUnitSoldQuantity"
        }, {
          "name": "MonthlyCurrencyValue"
        }, {
          "name": "MonthlyProductValue"
        }, {
          "name": "MonthlyValue"
        }, {
          "name": "Price"
        }, {
          "name": "ProjectedProfit"
        }, {
          "name": "Rate"
        }, {
          "name": "Ratio"
        }, {
          "name": "YearlyTotal"
        }]
      }, {
        "kind": "INTERFACE",
        "name": "Report",
        "possibleTypes": [{
          "name": "AllocationVsSales"
        }, {
          "name": "CurrencyRisk"
        }, {
          "name": "FinancialFlowTotals"
        }, {
          "name": "FinancialFlowsNetFlows"
        }, {
          "name": "FinancialFlowsSummary"
        }, {
          "name": "MonthlySalesRatiosByCurrency"
        }, {
          "name": "ProductForeignCogsRatios"
        }, {
          "name": "ProductPricesInPrimaryCurrency"
        }, {
          "name": "ProductQuantitiesByCurrency"
        }, {
          "name": "SalesBasedAllocation"
        }, {
          "name": "SensitivityImpact"
        }, {
          "name": "SummaryElements"
        }]
      }]
    }
  };
  _exports.default = _default;
});